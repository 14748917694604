<template>
  <div>
    <b-row>
      <b-col
        cols="24"
        xl="12"
        lg="12"
        md="12"
      >
        <abilities-templates-permissions-card :templatename="templatename" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

import { ref, onUnmounted } from '@vue/composition-api'

import store from '@/store'
import abilitiesTemplatesStoreModule from '../abilitiesTemplatesStoreModule'
import AbilitiesTemplatesPermissionsCard from './AbilitiesTemplatesPermissionsCard.vue'

export default {
  components: {
    BRow,
    BCol,

    AbilitiesTemplatesPermissionsCard,
  },
  setup() {
    const templatename = ref(null)

    const TEMPLATE_APP_STORE_MODULE_NAME = 'app-abilities-templates'

    // Register module
    if (!store.hasModule(TEMPLATE_APP_STORE_MODULE_NAME)) store.registerModule(TEMPLATE_APP_STORE_MODULE_NAME, abilitiesTemplatesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TEMPLATE_APP_STORE_MODULE_NAME)) store.unregisterModule(TEMPLATE_APP_STORE_MODULE_NAME)
    })

    return {
      templatename,
    }
  },
}
</script>

<style>

</style>
